import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaTags = ({ title, description, image, viewport, canonical, favicon }) => {
  return (
    <Helmet>
      {/* Title */}
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {title && <meta name="twitter:title" content={title} />}

      {/* Description */}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}

      {/* Image */}
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}

      {/* Canonical */}
      {canonical && <link rel="canonical" href={canonical} />}
      {canonical && <meta property="og:url" content={canonical} />}

      {/* Viewport */}
      {viewport && <meta name="viewport" content={viewport} />}

      {/* Favicon */}
      {favicon && <link rel="icon" type="image/x-icon" href={favicon} />}
      {favicon && <link rel="apple-touch-icon" href={favicon} />}
    </Helmet>
  );
};

MetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  viewport: PropTypes.string,
  canonical: PropTypes.string,
  favicon: PropTypes.string,
};

MetaTags.defaultProps = {
  title: null,
  description: null,
  image: null,
  viewport: null,
  canonical: null,
  favicon: null,
};

export default MetaTags;
